import React from 'react';

import { Glyphicon, Text } from 'reaxl';
import { PromoCard } from 'reaxl-listing';

import SrpWalletGridInputs from './SrpWalletGridInputs';

const customHeader = (
    <Text
        componentClass="div"
        size={400}
        weight="bold"
    >
        <Glyphicon
            className="text-size-500"
            glyph="wallet"
        />
        {' '}
        <Text>
            My Wallet
        </Text>
        {' '}
        <Text
            size={200}
            weight="light"
        >
            by Kelley Blue Book
            <sup>&#174;</sup>
        </Text>
    </Text>
);

function WalletGridPromoCardContainer() {
    return (
        <PromoCard
            className="col-xs-12 col-sm-4 display-flex"
            panelClassName="padding-bottom-0"
            header={customHeader}
            uiContext="wallet"
        >
            <SrpWalletGridInputs />
        </PromoCard>
    );
}

export default WalletGridPromoCardContainer;
