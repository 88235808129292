import _get from 'lodash/get';

import {
    articleCarouselClick,
    articlesCarouselImpression,
    clearAllFiltersClick,
    clearChipClick,
    closeStickyAdClick,
    compareTrimsClick,
    cpoFilterTileClick,
    customBuildConditionFilterClick,
    customOrderYours,
    electricVehicleFilterInfoClick,
    electricVehicleResearchArticleClick,
    emailSubscribeClick,
    emailSubscribeSignIn,
    facebookSubscribeSignIn,
    featureCodeClick,
    filterClick,
    informationHelpful,
    inventoryCarouselPagination,
    inventoryCertifiedTileClick,
    inventoryClick,
    inventoryImpressions,
    inventoryPaymentDetailsClick,
    inventorySave,
    inventorySearch,
    inventorySupplementalLoadMore,
    listingPaymentCTAClick,
    myRecentSearchClick,
    myRecentSearchImpression,
    numRecordsDropDownClick,
    ownerAccelerateClick,
    ownerImpressions,
    ownerLogoClick,
    ownerShowHideInventory,
    ownerViewInventory,
    page,
    paginationClick,
    psxPrivateSellerExchangeLogoClick,
    psxPrivateSellerPlacementClick,
    reportListingClick,
    saveSearchClick,
    saveSearchSuccess,
    searchRadiusClick,
    sendCaptureSubscribeClick,
    sendNotifyMeClick,
    sendReportClick,
    sendSubscribeAlertClick,
    sendSubscribeClick,
    snapshotPaymentDetailsClick,
    sortDropDownClick,
    srpRecentSearchToggle,
    suggestedFilterClick,
    totalNumberListingsImpressions,
    viewMoreVehiclesClick,
} from 'reaxl-analytics';

import {
    inventoryDuck,
    ownersDuck,
    userDuck,
} from '@/ducks';

import {
    srpFiltersDuck,
    srpNewCarBoostDuck,
    srpPaginationDuck,
    srpResultsDuck,
} from '@/ducks/srp';

import AlphaModule from '@/modules/AlphaModule';

const srpImpType = 'srpi';
const srpLabel = 'search-results-page';

export default {
    alphaImpression: (metadata, option) => {
        const { state } = metadata;
        const alpha = AlphaModule.duck.selectors.getDuckState(state);
        const isOwnerAlpha = !AlphaModule.duck.selectors.isListingAlpha(state);
        const inventory = inventoryDuck.selectors.getDuckState(state);
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        const showcaseListingId = alpha.listings[0];

        if (showcaseListingId && Object.prototype.hasOwnProperty.call(inventory, showcaseListingId) && !isOwnerAlpha) {
            return inventoryImpressions({
                ...metadata,
                data: {
                    cmp: 'alp',
                    pixallData: {
                        filterValues,
                        label: srpLabel,
                        listingType: 'alp',
                        impressionType: srpImpType,
                        listingCode: showcaseListingId,
                    },
                    selectedItemIds: [showcaseListingId],
                },
                userDuck,
            }, option);
        }

        // need to fetch separately if alpha has no inventory
        const alphaOwnerId = alpha.owner || '';

        if (!showcaseListingId || isOwnerAlpha) {
            return inventoryImpressions({
                ...metadata,
                data: {
                    cmp: 'dealer_alp',
                    pixallData: {
                        eventSource: 'dealership',
                        filterValues,
                        label: 'dealer-alpha',
                        listingType: 'alp',
                    },
                    selectedItemIds: [alphaOwnerId],
                },
            }, option);
        }

        return {};
    },

    articlesCarouselImpression: (metadata = {}, option) => {
        const { state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        return articlesCarouselImpression({
            ...metadata,
            data: { filterValues },
        }, option);
    },

    articleCarouselClick: (metadata = {}, option) => {
        const { data, state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        return articleCarouselClick({
            ...metadata,
            data: {
                ...data,
                filterValues,
            },
        }, option);
    },

    clearChipClick,

    clearAllFiltersClick,

    closeStickyAdClick,

    emailSubscribeClick,

    emailSubscribeSignIn,

    facebookSubscribeSignIn,

    filterClick: (metadata = {}, option) => filterClick({
        ...metadata,
        filterDuck: srpFiltersDuck,
    }, option),

    ownerShowHideInventory: (metadata, option) => ownerShowHideInventory({
        ...metadata,
        userDuck,
    }, option),

    ownerViewInventory,

    inventoryImpressions: (metadata, option) => {
        const { data, state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);

        const pixallData = {
            filterValues,
            isCarousel: data && data.isCarousel,
            impressionType: srpImpType,
            listingType: data && data.cmp,
            label: data.label || srpLabel,
            ...data?.pixallData,
        };

        // eslint-disable-next-line default-case
        switch (data.cmp) {
            case 'sim_v_lstg_':
                pixallData.label = 'sim_v_lstg';
                pixallData.listingType = 'similar';
                pixallData.impressionType = 'similar';
                break;
            case 'goodgreatprice':
            case 'interested':
            case 'numviewed':
            case 'reduced':
                pixallData.label = 'fyc_srl';
                break;
            case 'sim':
                pixallData.label = 'similar::spotlight';
                pixallData.listingType = 'sim';
                break;
            case 'ime':
                pixallData.label = 'ime';
                break;
            case 'still_interested':
                pixallData.label = 'still-interested::spotlight';
                pixallData.listingType = 'interested';
                break;

        }

        return inventoryImpressions({
            ...metadata,
            data: {
                ...data,
                cmp: data && data.cmp,
                pixallData,
                selectedItemIds: data && data.selectedItemIds,
            },
            resultsDuck: data && data.selectedItemIds ? undefined : srpResultsDuck,
            userDuck,
        }, option);
    },

    supplementalImpressions: (metadata, option) => {
        const { data, state } = metadata;
        const { rank, selectedItemIds } = data;

        const cmp = 'sup';

        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        return inventoryImpressions({
            ...metadata,
            data: {
                cmp,
                pixallData: {
                    filterValues,
                    isCarousel: false,
                    impressionType: srpImpType,
                    label: srpLabel,
                    listingType: cmp,
                    rank,
                },
                selectedItemIds,
            },
            userDuck,
        }, option);
    },

    spotlightImpressions: (metadata, option) => {

        const { state } = metadata;

        let cmp = 'spv_lstg_';
        let rank = 1;
        const selectedItemIds = metadata.data.activeInventory;
        if (metadata.data.spotlightOffset) {
            cmp += metadata.data.spotlightOffset;
            rank = metadata.data.spotlightOffset;
        }

        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        return inventoryImpressions({
            ...metadata,
            data: {
                cmp,
                pixallData: {
                    filterValues,
                    isCarousel: false,
                    impressionType: srpImpType,
                    label: srpLabel,
                    listingType: 'spv',
                    rank,
                },
                selectedItemIds,
            },
            userDuck,
        }, option);
    },

    newCarBoostImpressions: (metadata, option) => {
        const { state } = metadata;
        const boostListings = srpNewCarBoostDuck.selectors.getAvailableInventory(state);

        const cmp = 'ncb_v_lstg';
        const selectedItemIds = boostListings.map(({ id }) => id);

        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        return inventoryImpressions({
            ...metadata,
            data: {
                cmp,
                pixallData: {
                    filterValues,
                    isCarousel: false,
                    impressionType: srpImpType,
                    label: 'fyc_srl',
                    listingType: 'ncb',
                },
                selectedItemIds,
            },
            userDuck,
        }, option);
    },

    vehicleList: (metadata, option) => {
        const { state } = metadata;

        const selectedOwnerIds = [];
        const activeListings = srpResultsDuck.selectors.getActiveInventory(state);
        activeListings.map((listing) => {
            const { ownerId } = listing;
            if (!selectedOwnerIds.includes(ownerId)) {
                selectedOwnerIds.push(ownerId);
            }
        });

        option = {
            ...option,
            disableBirf: true,
        };

        return selectedOwnerIds.length ? ownerImpressions({
            ...metadata,
            data: {
                pixallData: {
                    label: srpLabel,
                    isList: 'true',
                },
                ownerIds: selectedOwnerIds,
            },
            ownersDuck,
            userDuck,
        }, option) : {};
    },

    page: (metadata, option) => {
        const { data, dataLayer, state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        const { stats: statFilterValue = {} } = srpResultsDuck.selectors.getDuckState(state);

        const makeCode = _get(filterValues, 'makeCodeList[0]', null);
        let modelCode = '';
        if (makeCode) {
            const model = `${makeCode === 'BMW' ? 'seriesCodeList|' : 'modelCodeList|'}${makeCode}`;
            modelCode = _get(filterValues, model, 'Any Model');
        }
        modelCode = modelCode.length > 0 ? modelCode : 'null';

        dataLayer.search.filters = {
            ...dataLayer.search.filters,
            endYear: statFilterValue.endYear || '',
            maxPrice: statFilterValue.maxPrice || '',
            minPrice: statFilterValue.minPrice || '',
            searchRadius: statFilterValue.searchRadius || 'rad',
            startYear: statFilterValue.startYear || '',
            zip: statFilterValue.zip || 'zip',
        };

        return page({
            ...metadata,
            data: {
                ...data,
                modelCode: modelCode.toString(),
                pageType: 'vlp',
            },
            filterDuck: srpFiltersDuck,
            userDuck,
        }, option);
    },

    cpoFilterTileClick: (metadata = {}, option) => {
        const { data, state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        return cpoFilterTileClick({
            ...metadata,
            data: {
                ...data,
                filterValues,
            },
        }, option);
    },

    customBuildConditionFilterClick,
    customOrderYours,

    keywordSearchSubmit: (metadata, option) => {
        const keywordPhrases = _get(metadata, 'data.codes.keywordPhrases', null) || _get(metadata, 'data.name', '');
        return inventorySearch({
            ...metadata,
            data: {
                ...metadata.data,
                isKeywordSearch: true,
                keywordPhrases,
            },
        }, option);
    },

    inventoryCertifiedTileClick: (metadata, option) => inventoryCertifiedTileClick({
        ...metadata,
        userDuck,
    }, option),

    inventoryClick: (metadata, option) => {
        const { data, state } = metadata;
        const pixallData = { ...data.pixallData };
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);

        if (data.par === 'sim_v_lstg') {
            pixallData.listingType = 'similar';
        }

        if (data.par === 'interested_spotlight') {
            pixallData.listingType = 'interested';
        }

        return inventoryClick({
            ...metadata,
            data: {
                ...data,
                pixallData: {
                    ...pixallData,
                    filterValues,
                },
            },
            userDuck,
        }, option);
    },

    inventorySave: (metadata, option) => {
        const { data } = metadata;
        const pixallData = { ...data.pixallData };

        if (data.par === 'sim_v_lstg' && !pixallData.listingType) {
            pixallData.listingType = 'similar';
        }

        return inventorySave({
            ...metadata,
            data: {
                ...data,
                pixallData,
            },
            userDuck,
        }, option);
    },

    inventoryPaymentDetailsClick: (metadata, option) => inventoryPaymentDetailsClick({
        ...metadata,
    }, option),

    listingPaymentCTAClick: (metadata, option) => listingPaymentCTAClick({
        ...metadata,
    }, option),

    ownerAccelerateClick,

    snapshotPaymentDetailsClick: (metadata, option) => snapshotPaymentDetailsClick({
        ...metadata,
        userDuck,
    }, option),

    totalNumberListingsImpressions: (data, option) => totalNumberListingsImpressions({
        ...data,
    }, option),

    informationHelpful,
    electricVehicleFilterInfoClick,
    sendReportClick,
    reportListingClick,
    electricVehicleResearchArticleClick,

    inventorySupplementalLoadMore: (metadata, option) => inventorySupplementalLoadMore({
        ...metadata,
        userDuck,
    }, option),

    inventoryCarouselPagination,

    ownerLogoClick: (metadata, option) => ownerLogoClick({
        ...metadata,
        userDuck,
    }, option),

    srpSortDropDownClick: (metadata, option) => sortDropDownClick({
        ...metadata,
        filterDuck: srpFiltersDuck,
        paginationDuck: srpPaginationDuck,
        resultsDuck: srpResultsDuck,
        userDuck,
    }, option),

    srpNumRecordsDropDownClick: (metadata, option) => numRecordsDropDownClick({
        ...metadata,
        filterDuck: srpFiltersDuck,
        paginationDuck: srpPaginationDuck,
        resultsDuck: srpResultsDuck,
        userDuck,
    }, option),

    srpPaginationClick: (metadata, option) => paginationClick({
        ...metadata,
        filterDuck: srpFiltersDuck,
        paginationDuck: srpPaginationDuck,
        resultsDuck: srpResultsDuck,
        userDuck,
    }, option),

    searchRadiusClick,

    featureCodeClick,

    saveSearchClick,

    saveSearchSuccess,

    sendNotifyMeClick,

    sendSubscribeClick,

    sendSubscribeAlertClick,

    sendCaptureSubscribeClick,

    suggestedFilterClick,

    srpRecentSearchToggle,

    compareTrimsClick,

    myRecentSearchClick,

    myRecentSearchImpression,

    psxPrivateSellerPlacementClick: (metadata = {}, option) => {
        const { data, state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        return psxPrivateSellerPlacementClick({
            ...metadata,
            data: {
                ...data,
                filterValues,
            },
        }, option);
    },

    psxPrivateSellerExchangeLogoClick,

    viewMoreVehiclesClick,
};
