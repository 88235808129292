import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import _chunk from 'lodash/chunk';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

// bonnet
import { useDevice } from '@bonnet/next/device';

// @atc
import { mapDispatchToActionProp } from '@atc/modular-redux';

import {
    formattedNumber,
} from 'atc-js';

import { SubHeading } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { brands, useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';
import {
    InventoryListingPlaceholder,
    InventoryListingV2,
} from 'reaxl-listing';

import configureListingTiles from '@/utilities/configureListingTiles';
import getDynamicSpecifications from '@/utilities/dynamicSpecificationUtil';
import getCpoByMake from '@/utilities/getCpoByMake';
import saveListing from '@/utilities/saveListing';
import getSpecificationKeys from '@/utilities/specificationUtil';
import vdpUrlBuilder from '@/utilities/vdpUrlBuilder';

import { getClickType, getParentId } from '@/analytics/searchResultsAnalyticsHelpers';

import {
    activeInteractionDuck,
    authDuck,
    compareListingsDuck,
    cpoContentDuck,
    globalConfigsDuck,
    ownersDuck,
    paymentsDuck,
    savedInventoryDuck,
    userDuck,
    userPreferencesDuck,
} from '@/ducks';

import {
    srpActiveInteractionDuck,
    srpAdsDuck,
    srpFiltersDuck,
    srpLocationTypeDuck,
    srpNewCarBoostDuck,
    srpPaginationDuck,
    srpPrimeSpotlightDuck,
    srpResultsDuck,
    srpSpotlightDuck,
    srpSupplementalDuck,
} from '@/ducks/srp';
import { vdpActiveInteractionDuck } from '@/ducks/vdp';

import PaymentCalculationFetcher from '@/fetchers/PaymentCalculationFetcher';

import useVdpNavigation from '@/hooks/useVdpNavigation';

import { AlphaModule } from '@/modules';

import PersonalizationEngineModule from '@/modules/PersonalizationEngineModule';

import LazyComponent from '@/components/LazyComponent';

import InventoryResultsHandlers from './analytics/InventoryResultsHandlers';
import usePremiumSpotlightUrgencyDrivers from './hooks/usePremiumSpotlightUrgencyDrivers';
import InventoryBoostContainer from './InventoryBoostContainer';
import InventoryQuickActionsContainer from './InventoryQuickActionsContainer';
import InventorySpotlightContainer from './InventorySpotlightContainer';
import ListingsLinerAds from './ListingsLinerAds';
import MyWalletGridContainer from './MyWalletGridContainer';
import MyWalletPlacementContainer from './MyWalletPlacementContainer';
import PremiumSpotlightContainer from './PremiumSpotlightContainer';
import PrivateSellerPlacementContainer from './PrivateSellerPlacementContainer';
import SellYourCarPlacementContainer from './SellYourCarPlacementContainer';
import SRPDealerDiffModalWrapper from './SRPDealerDiffModalWrapper';
import SRPJumpLink from './SRPJumpLink';
import SRPListingCollection from './SRPListingCollection';
import WalletGridPromoCardContainer from './WalletPromoCardContainer';

// lazy
export const EVArticlesCarouselContainer = dynamic(() => import(
    /* webpackChunkName: "EVArticlesCarouselContainer" */
    '@/containers/srp/EVArticlesCarouselContainer'
), {
    ssr: false,
});
export const PreorderSearchContainer = dynamic(() => import(
    /* webpackChunkName: "PreorderSearchContainer" */
    '@/containers/srp/PreorderSearchContainer'
), {
    ssr: false,
    loading: () => (
        <InventoryListingPlaceholder
            className="col-xs-12 col-sm-4"
            data-cmp="inv-placeholder-preorder"
            key="preorder-inventory-placeholder"
        />
    ),
});
export const CertifiedPreOwnedContainer = dynamic(() => import(
    /* webpackChunkName: "CertifiedPreOwnedContainer" */
    '@/containers/srp/CertifiedPreOwnedCard'
), {
    ssr: false,
    loading: () => (
        <InventoryListingPlaceholder
            className="col-xs-12 col-sm-4"
            data-cmp="inv-placeholder-cpoCard"
            key="inventory-placeholder-cpoCard"
            portrait
        />
    ),
});
export const SupplementalSearchContainer = dynamic(() => import(
    /* webpackChunkName: "SupplementalSearchContainer" */
    '@/containers/srp/SupplementalSearchContainer'
), {
    ssr: false,
    loading: () => (
        Array(6).fill().map((_, index) => (
            <InventoryListingPlaceholder
                className="col-xs-12 col-sm-4"
                data-cmp="inv-placeholder-supplemental"
                key={`supplemental-placeholder-${index}`}
            />
        ))
    ),
});
export const SimilarInventoryContainer = dynamic(() => import(
    /* webpackChunkName: "SimilarInventoryContainer" */
    '@/containers/srp/SimilarInventoryContainer'
), {
    ssr: false,
    loading: () => (
        Array(6).fill().map((_, index) => (
            <InventoryListingPlaceholder
                className="col-xs-12 col-sm-4"
                data-cmp="inv-placeholder-similar"
                key={`similar-placeholder-${index}`}
            />
        ))
    ),
});
export const SpotlightCarouselContainer = dynamic(() => import(
    /* webpackChunkName: "SpotlightCarouselContainer" */
    '@/containers/srp/SpotlightCarouselContainer'
), { ssr: false });

/*
 * render list of inventory listings as spotlights, regular and supplemental listings
 */
function InventorySearchResultsContainerComponent({
    actions,
    adsLazyLoadingOffset,
    birf,
    certifiedSponsor,
    filtersValues,
    hasAlphaListings,
    isLastPage,
    listings = [],
    savedListingIds,
    resultCount = 0,
    compareListingIds,
    currentPage,
    resultsPerPage,
    searchRadius,
    isLoggedIn,
}) {

    const dispatch = useDispatch();
    const { brand, isBrand } = useBrand();
    const isKbbBranded = isBrand(brands.KBB_BRAND);
    const isFordBranded = isBrand(brands.FORD_BRAND);

    const aemHost = useSelector(globalConfigsDuck.selectors.getAemHost);
    const showListingWalletPayment = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'paymentEmphasis'));
    const dealDetails = useSelector(paymentsDuck.selectors.getTransformedDealDetails, _isEqual);
    const userLocation = useSelector(userDuck.selectors.getCityState);
    const spotlights = useSelector(srpSpotlightDuck.selectors.getActiveInventory, _isEqual);
    const premiumSpotlights = useSelector(srpPrimeSpotlightDuck.selectors.getActiveInventory, _isEqual);
    const isResultsLoading = useSelector(srpResultsDuck.selectors.getResultLoading);
    const boostListings = useSelector(srpNewCarBoostDuck.selectors.getAvailableInventory, _isEqual);
    const supplementalResultsCount = useSelector(srpSupplementalDuck.selectors.getResultCount);
    const supplementalResultsLoaded = useSelector(srpSupplementalDuck.selectors.getLoadedCount);
    const supplementalInitialLoad = useSelector(srpSupplementalDuck.selectors.getInitialLoad);
    const supplementalLoading = useSelector(srpSupplementalDuck.selectors.getLoading);
    const isFiltersVisible = useSelector(srpFiltersDuck.selectors.getFiltersVisible);

    const owners = useSelector(ownersDuck.selectors.getOwners);
    const leadProfile = useSelector(userDuck.selectors.getLeadProfile);
    const makeFilterValues = useSelector(srpFiltersDuck.selectors.getMakeFilterValues);
    const listingTypeFilterValues = useSelector(srpFiltersDuck.selectors.getListingTypeValues);

    const urgencyDriverData = usePremiumSpotlightUrgencyDrivers({ spotlight: premiumSpotlights[0] });

    const isOneClickOptedIn = useSelector(activeInteractionDuck.selectors.isOneClickOptedIn);
    const { isOneClickOptedIn: leadProfileOptedIn } = leadProfile || {};
    const [oneClickListingIds, setOneClickListingIds] = useState([]);

    const previousListingsRef = useRef(listings);
    const isNationalSRP = useSelector(srpLocationTypeDuck.selectors.getDuckState);

    useEffect(() => {
        // TODO: refactor this to not use the listings and instead usePageEvent for changing on a "new page"
        if (previousListingsRef.current !== listings) {
            previousListingsRef.current = listings;
        }
    }, [listings]);

    const changeWalletPlacement = previousListingsRef.current !== listings;

    useEffect(() => {
        if (userDuck && !leadProfile) {
            dispatch(userDuck.creators.setLeadProfile());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        accelerate: [, {
            enable_listing_badge: enableListingBadge,
        }],
        dealer_ratings: [isDealerRatingEnabled],
        disable_supplemental_listings: [disableSupplementalListings], // optimizely switch
        disable_similar_listings_srp: [disableSimilarListings],
        enable_360_image: [enable360Image],
        enable_new_car_boost: [enableNewCarBoost],
        est_payments: [isEstPaymentsEnabled],
        home_services: [isHomeServicesEnabled],
        personalization: [isPersonalizationEnabled, { listings_saved: authenticatedSavesOnly }],
        spotlight: [isSpotlightEnabled], // feature config
        supplemental: [supplementalEnabled],
        SRP_DEALERNAME_CLICKABLE: [isDealerNameClickable],
        brand: [, { base_url: baseUrl, leads_cc_flag: copySelf, leads_partner_name: partnerName }],
        one_click_lead: [isOneClickLeadEnabled],
        override_carfax_tile: [isOverrideCarfaxTileEnabled, { partner }],
        market_extension: [marketExtensionEnabled],
        my_wallet: [enableMyWallet],
        nds: ndsFeature,
        ev_articles_srp: [enableEVArticleSrp],
        disable_retail_engine: [disableRetailEngine],
        premium_spotlight: [enablePremiumSpotlight, {
            peekaboo_placement: peekabooPlacementHigher,
            peekaboo_placement_10: peekabooPlacementLower,
            multi_carousel_3: enableMultiCarousel3,
            multi_carousel_9: enableMultiCarousel9,
        }],
        schema: [, { listing_type: schemaType, vehicle_feature_description: featureDescription, vehicle_price_description: priceDescription }],
        srp_promo_cards: [enableSrpPromoCards],
        srp_wallet_grid_promo_card: [enableNewWalletPromoCard],
        remove_carfax_tile: [removeCarfaxTile],
        ev_articles: [isEVArticlesEnabled],
        psx_cta_webcomponents: [, { version = '', branch = 'main' }],
        psx_config: [, { environment = 'prod' }],
        oem_cpo_srp: [, { listing_card: isOEMListingCard }],
    } = useFeatures([
        'accelerate',
        'dealer_ratings',
        'disable_spotlights',
        'disable_pkg_and_specs',
        'disable_supplemental_listings',
        'disable_similar_listings_srp',
        'est_payments',
        'enable_360_image',
        'compare',
        'home_services',
        'enable_new_car_boost',
        'spotlight',
        'supplemental',
        'personalization',
        'SRP_DEALERNAME_CLICKABLE',
        'brand',
        'one_click_lead',
        'override_carfax_tile',
        'market_extension',
        'my_wallet',
        'nds',
        'disable_retail_engine',
        'premium_spotlight',
        'schema',
        'srp_promo_cards',
        'srp_third_spotlight_index',
        'srp_wallet_grid_promo_card',
        'remove_carfax_tile',
        'ev_articles_srp',
        'ev_articles',
        'psx_cta_webcomponents',
        'psx_config',
        'oem_cpo_srp',
    ]);

    const [showSimilarListings, setShowSimilarListings] = useState(false);

    const hasSearchRadius = !!searchRadius;
    let carouselThreeSpotlightListings = [...premiumSpotlights];
    let carouselNineSpotlightListings = [...premiumSpotlights];

    const shouldLoadSimilarListings = !isNationalSRP && hasSearchRadius;

    useEffect(() => {
        if (!shouldLoadSimilarListings) {
            return;
        }

        if (showSimilarListings && supplementalResultsCount === 0 && supplementalResultsLoaded === 0 && supplementalInitialLoad) {
            // detect that srpSupplementalDuck has been cleared on SupplementalSearchContainer useEffect
            setShowSimilarListings(false);
        } else if (!showSimilarListings && !supplementalLoading && !supplementalInitialLoad && !isResultsLoading) {
            const isLowResultCount = resultCount + supplementalResultsCount <= 25;
            const supplementalListingsRendered = supplementalResultsCount > 0 && ((supplementalResultsLoaded >= supplementalResultsCount) || supplementalResultsLoaded === 100);
            const noSupplementalLoaded = !supplementalInitialLoad && !supplementalLoading && supplementalResultsCount === 0;
            const isSupplementalDoneLoading = supplementalListingsRendered || noSupplementalLoaded;
            if (!disableSimilarListings && isLowResultCount && isSupplementalDoneLoading && hasSearchRadius) {
                setShowSimilarListings(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supplementalResultsCount, supplementalResultsLoaded, supplementalInitialLoad, supplementalLoading, resultCount, showSimilarListings, isResultsLoading, isNationalSRP, shouldLoadSimilarListings]);

    const schemaTemplate = {
        featureDescription,
        priceDescription,
    };

    const device = useDevice();
    const notXS = _get(device, 'greaterThan.xs', false);

    const [, {
        disable_payments: isPaymentsDisabled,
    }] = ndsFeature;

    const navigateToVdp = useVdpNavigation();

    // Clean up eventBus and script tag when component unmount
    useEffect(() => {
        ['psx.listingActions.openMakeOffer', 'psx.listingActions.makeOfferModalIsLoaded'].forEach(window.caiEventBus.off);
        document.querySelector('script[src*="static.kbb.com/psx-listing-actions"]')?.remove();
    }, []);

    const {
        saveListing: saveInventoryListing,
    } = actions;

    // Spotlight experiments
    const enableSpotlightCarousel = enablePremiumSpotlight && premiumSpotlights.length > 2;
    const showPeekabooHigher = enablePremiumSpotlight && peekabooPlacementHigher && !_isEmpty(urgencyDriverData);
    const showPeekabooLower = enablePremiumSpotlight && peekabooPlacementLower && !_isEmpty(urgencyDriverData);
    const showPeekabooSpotlight = showPeekabooHigher || showPeekabooLower;
    const showTwoCarousels = enablePremiumSpotlight && enableMultiCarousel3 && enableMultiCarousel9;

    // Show supplemental results condition
    const showSupplementalResults = isLastPage && hasSearchRadius;

    const cpoData = useMemo(() => getCpoByMake(makeFilterValues, certifiedSponsor), [makeFilterValues, certifiedSponsor]);
    const displayCPOCTA = cpoData && !listingTypeFilterValues.includes('CERTIFIED');
    const isMakeSelected = makeFilterValues.length > 0;
    // Show CPO Card has CPO make, and cpo not selected and display the latest selected make card only.
    const showCPOCard = !!isMakeSelected && displayCPOCTA && isOEMListingCard;

    const peData = useSelector((state) => {
        const allData = PersonalizationEngineModule.duck.selectors.selectPersonalization(state) || {};
        return {
            preferredFuel: _get(allData, 'PrefFuelCat', null),
            recentPreferredFuel: _get(allData, 'RecentPrefFuelCat', null),
            marketingSegments: _get(allData, 'pe_clusters', []),
        };
    }, _isEqual);

    const compareToggle = useSelector(userPreferencesDuck.selectors.getCompareToggle);
    const showEVArticleCarousel = (isEVArticlesEnabled && enableEVArticleSrp);

    const specificationKeys = useMemo(() => getSpecificationKeys(filtersValues, peData), [filtersValues, peData]);

    const getListingSpecificationKeys = useCallback((listing) => (listing.specifications ? specificationKeys.filter((key) => listing.specifications[key]) : []), [specificationKeys]);

    // Retrieve saved inventory ids and set them in the inventory reducer
    const initializeUserInfo = useCallback(() => {
        dispatch(userDuck.creators.hydrateUserInventory({ isLoggedIn, onLoggedInStateReady: () => { } }));
    }, [dispatch, isLoggedIn]);

    // Retrieve recently viewed inventory ids and set them in the inventory reducer
    const initializeRecentInventory = useCallback(() => {
        dispatch(userDuck.creators.hydrateRecentInventory({ isLoggedIn, onLoggedInStateReady: () => { } }));
    }, [dispatch, isLoggedIn]);

    useEffect(() => {
        initializeRecentInventory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*
      * This useEffect hook is intended to fire only on component mount
      * and will request a fourth spotlight if necessary as well as
      * fire all initial page-loaded spotlight listings
      */
    useEffect(() => {
        // Initialize saved listing and other user data
        // Only call this function on KBB
        if (isKbbBranded) {
            initializeUserInfo();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isActiveDeal = useCallback((listing) => {
        const dealVins = Object.keys(dealDetails).map((dealId) => dealDetails[dealId]?.vehicle?.vin);

        if (dealVins) {
            return dealVins.includes(listing?.vin);
        }

        return false;
    }, [dealDetails]);

    const sendDealerDiffCtaClick = useCallback((event, data) => {
        sendClick('inventoryDealerDiffClick', event, data);

    }, []);

    const handleDealerDiffModalToggle = useCallback(() => dispatch(srpActiveInteractionDuck.creators.setKeys({ showDealerDiffModal: true })), [dispatch]);

    const handleDealerDiffCTAClick = InventoryResultsHandlers.useDealerDiffCTAClick(aemHost, handleDealerDiffModalToggle, sendDealerDiffCtaClick);

    const handleTelMetric = InventoryResultsHandlers.useTelMetric(sendClick, birf);

    const handleCompareListingsClick = InventoryResultsHandlers.useCompareListingsClick(sendClick, compareListingIds);

    const handleEmailOwnerClick = InventoryResultsHandlers.useEmailOwnerClick(sendClick, showPeekabooSpotlight, copySelf, partnerName, oneClickListingIds, setOneClickListingIds);

    const handleEditEmailClick = InventoryResultsHandlers.useEditEmailClick();

    const handleListingAnalyticClick = InventoryResultsHandlers.useListingAnalyticClick(sendClick);

    const handleListingNavigationClick = InventoryResultsHandlers.useListingNavigationClick(dispatch, handleListingAnalyticClick, navigateToVdp, vdpUrlBuilder);

    const handleListingItemClick = InventoryResultsHandlers.useListingItemClick(sendClick);

    const handleListingWalletClick = InventoryResultsHandlers.useListingWalletClick(sendClick);

    const handlePaymentCTAClick = InventoryResultsHandlers.usePaymentCTAClick(sendClick);

    const handleSeeVehicleDetailsClick = InventoryResultsHandlers.useSeeVehicleDetailsClick(sendClick, filtersValues);

    const handleCheckDealStatusClick = InventoryResultsHandlers.useCheckDealStatusClick(sendClick, filtersValues);

    const handlePsxListingMakeOfferClick = InventoryResultsHandlers.usePsxListingMakeOfferClick(sendClick, filtersValues, branch, environment, version);

    const personalPaymentProps = {
        fetcher: PaymentCalculationFetcher,
        paymentsEnabled: !disableRetailEngine,
        ndsFeature,
    };

    const getListingProps = (listingCategory, listing, index, paginatedListingIndex) => {
        let lazyLoad = true;
        if ((index === 0 && !hasAlphaListings)
            || (notXS
                && (listingCategory === 'spotlight' && index < 3)
                || (listingCategory === 'listings' && (index + Math.min(spotlights.length, 3)) < 3))) {
            lazyLoad = false;
        }

        let listingContext;
        switch (listingCategory) {
            case 'spotlight':
                listingContext = 'sponsored';
                break;
            case 'boost':
                listingContext = 'recommended';
                break;
            case 'peekaboo':
                listingContext = 'simplified';
                break;
            default:
                listingContext = 'default';
        }

        if (listing.marketExtension?.imeDmaMatch) {
            listingCategory = 'ime';
        }

        const clickType = getClickType(listingCategory, urgencyDriverData.type);
        const parentId = getParentId(listingCategory, index, urgencyDriverData.type);

        const analyticsData = {
            inventoryId: listing?.id,
            clickType,
            parentId,
            paginatedListingIndex,
            listing,
            listingCategory,
        };

        const listingOwner = owners[listing.ownerId] || {};
        // TODO: to prevent the need to bind `listing` to `handleListingClick`, etc., InventoryListing should be modified
        // to pass `listing` back to `onClick` handlers the same way it does for `onSaveToggle` handlers.
        const listingSpecificationKeys = getListingSpecificationKeys(listing);
        const selectedMakeCode = _get(filtersValues, 'makeCodeList', '') || _get(filtersValues, 'makeCode', '');
        const isDynamicListingsEnabled = !selectedMakeCode;
        const dynamicSpecifications = isDynamicListingsEnabled ? getDynamicSpecifications(filtersValues, listing) : [];
        const onDealerDiffCTAClick = {
            onDealerDiffCTAClick: handleDealerDiffCTAClick.bind(null, listing, clickType, paginatedListingIndex, listingOwner, parentId),
        };
        const payments = ((enableMyWallet && isPaymentsDisabled) || !isEstPaymentsEnabled) ? null : _get(listing, 'pricingDetail.payments', null);
        const dealerHomeServices = isHomeServicesEnabled ? _get(listingOwner, 'homeServices', {}) : null;
        const dealerRatings = isDealerRatingEnabled ? _get(listingOwner, 'rating', {}) : null;
        const ownerWebsite = isDealerNameClickable ? _get(listingOwner, 'website', {}) : null;
        const isDealerDeliveryAvailable = marketExtensionEnabled ? _get(listingOwner, 'shippingLabel', '') : null;
        const listingCTAHref = vdpUrlBuilder({ baseUrl: _get(listing, 'vdpBaseUrl'), clickType });

        const onSaveToggle = (event, savedListing, wasSaved) => {
            saveInventoryListing({
                event,
                listing: {
                    ...savedListing,
                    tier: {
                        name: listingCategory,
                        index,
                        ...(showPeekabooSpotlight ? { urgencyDriverType: urgencyDriverData.type } : {}),
                    },
                },
                wasSaved,
                sendClick,
            });
        };

        const onToggleKBBDealerRatingModal = (event) => {
            event.stopPropagation();

            dispatch(vdpActiveInteractionDuck.creators.setKeys({
                showKBBDealerModal: true,
            }));

            handleListingNavigationClick({ ctaType: 'inventory', ...analyticsData })(event);
        };

        let listingTitleWithNoPkg = (listing.title) && (listing.title).substring(0, (listing.title).indexOf(' w/'));
        const showCompareButton = compareToggle && compareListingIds?.length < 5;

        // TODO: this should use a brand config, not check for isBrand
        const listingQuickActions = isFordBranded ? null : (
            <InventoryQuickActionsContainer
                clickType={clickType}
                listing={listing}
                onEmailClick={handleEmailOwnerClick}
                parentId={parentId}
                phoneClickHandler={handleTelMetric}
            />
        );

        // Remove listingType from listing title when only 1 type is selected in condition filter
        // Display certified title only when used condition filter is selected
        if (filtersValues.listingType && filtersValues.listingType.length === 1 && (filtersValues.listingType.includes('CERTIFIED')
            || filtersValues.listingType.includes('NEW') || (filtersValues.listingType.includes('USED') && listing.listingType !== 'CERTIFIED'))) {
            const make = listing?.make?.name || listing?.make;
            const model = listing?.model?.name || listing?.model;
            const trim = listing?.trim?.name || '';

            listingTitleWithNoPkg = `${listing.year} ${make} ${model} ${trim}`;
        }

        return {
            className: 'cursor-pointer display-flex flex-column',
            clickType,
            emailOwnerProps: {
                parentId,
                onCTAClick: handleEmailOwnerClick(listing, parentId, clickType),
                context: showPeekabooSpotlight ? 'peekaboo-spv' : '',
                labelKey: 'checkAvailability',
            },
            handleEditEmail: handleEditEmailClick(listing, parentId, clickType),
            id: String(listing.id),
            isActiveDeal: isActiveDeal(listing),
            isPhoneNumberCallable: !notXS,
            isSaved: savedListingIds.includes(listing.id),
            isCompared: compareListingIds && compareListingIds.includes(listing.id),
            show360: enable360Image && _get(listing, 'fyuseId', false),
            key: `listing_${index}`,
            lazyLoad,
            loading: lazyLoad ? 'lazy' : 'eager',
            listing: {
                ...listing,
                isHot: listing.isHot,
                title: listingTitleWithNoPkg,
                pricingDetail: {
                    ...listing.pricingDetail,
                    payments,
                },
                owner: {
                    ...listingOwner,
                    dealerDiff: listingOwner.dealerDiff && Object.assign(listingOwner.dealerDiff, { label: listingOwner.dealerDiff?.tagline }),
                    distanceFromSearch: listingOwner.distanceFromSearch && Math.round(listingOwner.distanceFromSearch * 100) / 100 || null,
                    homeServices: dealerHomeServices,
                    phone: listing.phone, // add back the phone that we saved earlier in dispatchutils specific to the listing
                    rating: dealerRatings,
                    shippingLabel: isDealerDeliveryAvailable,
                    website: ownerWebsite,
                },
                website: {
                    href: listingCTAHref,
                },
            },
            listingCTA: {
                title: listing.title,
                href: listingCTAHref,
            },
            listingQuickActions,
            onCertifiedTileClick: handleListingNavigationClick({ ctaType: 'certifiedTile', ...analyticsData, anchor: 'warrantySection', tileClick: 'true' }),
            onClick: handleListingNavigationClick({ ctaType: 'inventory', ...analyticsData }),
            onContextMenu: handleListingAnalyticClick({ ctaType: 'inventory', ...analyticsData }),
            onPricingDetailClick: handleListingNavigationClick({ ctaType: 'pricing', ...analyticsData, anchor: 'pricingSection' }),
            onProductTileClick: handleListingItemClick({ ctaType: 'product', ...analyticsData }),
            onProductTileLinkClick: handleListingItemClick({ ctaType: 'product', ...analyticsData }),
            phoneClickHandler: (phoneNumber) => handleTelMetric(listing, phoneNumber, clickType),
            oneClickEmailSent: oneClickListingIds.includes(listing.id),
            onSaveToggle: isPersonalizationEnabled || authenticatedSavesOnly ? onSaveToggle : null,
            onCompareClick: showCompareButton && handleCompareListingsClick(listing),
            'data-qaid': listingCategory === 'spotlight' ? '' : `cntnr-listing-${listing.id}`, // TODO remove in favor of data-cmp
            orientation: 'portrait',
            paymentsCTA: {
                onClick: handleListingItemClick({ ctaType: 'payment', ...analyticsData }),
            },
            parentId,
            showEditEmail: isOneClickLeadEnabled && (isOneClickOptedIn || leadProfileOptedIn),
            showEllipses: true,
            showDistance: _get(listingOwner, 'distanceFromSearch', 0) > 0,
            showPackages: true,
            specificationKeys: listingSpecificationKeys,
            dynamicSpecifications,
            showListingPaymentRibbon: !!enableMyWallet && (!!showListingWalletPayment || listingContext === 'recommended'),
            enableMyWallet: !!enableMyWallet,
            onMyWalletClick: handleListingWalletClick(analyticsData),
            updateListingPayments: (args) => dispatch(paymentsDuck.creators.updateListingPayments(args)),
            personalPaymentProps,
            uiContext: listingContext,
            ...onDealerDiffCTAClick,
            onPaymentCTAClick: handlePaymentCTAClick(analyticsData),
            paginatedListingIndex,
            onOnlinePaperworkClick: handleListingNavigationClick({ ctaType: 'homeServices', ...analyticsData, anchor: 'nativeDealContainer' }),
            onActiveDealDetailsClick: handleSeeVehicleDetailsClick({ listing }),
            onCheckStatusClick: handleCheckDealStatusClick({ listing }),
            onToggleModal: onToggleKBBDealerRatingModal,
            onSelectPsxListing: (event) => handlePsxListingMakeOfferClick(event, analyticsData),
        };
    };

    /**
     * render a special listing that is not part of the base listings call
     *
     * @param listingIndex index at which the spotlight should be added
     * @param listingCategory type of special listing
     * @returns {*} the markup with spotlight(s) being injected in the results
     */

    const renderSpecialListing = (listingIndex, listingCategory = 'spotlight') => {
        const listingProps = {
            baseUrl,
            getListingProps,
            schemaTemplate,
            schemaType,
            userLocation,
            currentPage,
            listingIndex,
            brand,
            certifiedSponsor,
            isOverrideCarfaxTileEnabled,
            partner,
            removeCarfaxTile,
        };

        if (isFiltersVisible) {
            return (
                <InventoryListingPlaceholder
                    className="col-xs-12 col-sm-4"
                    data-cmp="inv-placeholder-lstg"
                    key={`${listingCategory}-placeholder-${listingIndex}`}
                />
            );
        }

        if (listingCategory === 'spotlight' && !!isSpotlightEnabled) {
            return (
                <InventorySpotlightContainer
                    key={`spotlight-${listingIndex}`}
                    {...listingProps}
                />
            );
        }

        if (listingCategory === 'boost') {
            return (
                <InventoryBoostContainer
                    key={`boost-${listingIndex}`}
                    {...listingProps}
                />
            );
        }

        if (listingCategory === 'peekaboo') {
            return (
                <PremiumSpotlightContainer
                    premiumSpotlight={premiumSpotlights[0]}
                    urgencyDriverData={urgencyDriverData}
                    {...listingProps}
                />
            );
        }

        return () => { };
    };

    const listingsWithTiles = configureListingTiles({
        brand,
        certifiedSponsor,
        items: listings,
        isOverrideCarfaxTileEnabled,
        partner,
        tier: 'listing',
        removeCarfaxTile,
    });

    const renderResultsCount = (count) => (
        <SubHeading
            componentClass="h2"
            className="padding-bottom-4"
            size={500}
            data-cmp="resultsCount"
        >
            {formattedNumber({ value: count }) + ' Results'}
            <SRPJumpLink />
        </SubHeading>
    );

    const renderEVArticleCarousel = showEVArticleCarousel && (
        <LazyComponent
            key="EVArticlesCarousel"
        >
            <div className="col-xs-12 margin-bottom-5">
                <EVArticlesCarouselContainer />
            </div>
        </LazyComponent>
    );

    // TODO: maybe just set to invisible instead of unmounting when there are not enough listings?
    const renderLinerAd = (index) => (
        <div
            className="col-xs-12 margin-bottom-5"
            key={`liner-ad-${index}`}
        >
            <ListingsLinerAds
                adsLazyLoadingOffset={adsLazyLoadingOffset}
                index={index}
                notXs={notXS}
            />
        </div>
    );

    const renderSupplementalListings = !!supplementalEnabled && !disableSupplementalListings && (
        <SupplementalSearchContainer
            getListingProps={getListingProps}
            listingsWithTilesLength={listingsWithTiles.length}
            showSupplementalResults={showSupplementalResults}
            isLastPage={isLastPage}
        />
    );

    const searchResultsListings = listingsWithTiles?.map((listing, index) => {
        const paginatedListingIndex = (index + 1) + ((currentPage - 1) * resultsPerPage);

        if (!enableListingBadge) {
            listing.accelerate = false;
        }

        return isResultsLoading || isFiltersVisible
            ? (
                <InventoryListingPlaceholder
                    className="col-xs-12 col-sm-4"
                    data-cmp="inv-placeholder-lstg"
                    key={`inventory-placeholder-${index}`}
                />
            ) : (
                <div
                    className="col-xs-12 col-sm-4 display-flex"
                    key={`listing-${index}`}
                >
                    <InventoryListingV2 {...getListingProps('listings', listing, index, paginatedListingIndex)} />
                </div>
            );
    });

    /*
     * Inject 4 Spotlights into the SRP results if available:
     * 2 Premium spotlights between the Alpha and the search results
     * 1 Premium spotlight after the 5th listing of the search results
     * 1 Non-Premium spotlight after the 20th listing. Show a Featured over a Standard spotlight if there are the same
     *  or more featured than standard organic listings
     */
    /* eslint-disable no-unused-expressions*/
    // insert spotlights at specific indexes
    [0, 1, 2, 23].forEach((position, index) => {
        spotlights?.[index]?.id && searchResultsListings.splice(position, 0, renderSpecialListing(index));
    });

    /*
    * Inject My Wallet promo grid placement at spot #4
      will be injected in a row separately when higher peekaboo variant is on
    */
    if (enableNewWalletPromoCard) {
        searchResultsListings.splice(3, 0, <WalletGridPromoCardContainer key="myWalletPromoCard" />);
    }

    if (showPeekabooHigher) {
        premiumSpotlights[0] && searchResultsListings.splice(4, 0, renderSpecialListing(4, 'peekaboo'));
        searchResultsListings.splice(5, 0, null);
    }

    if (showPeekabooLower) {
        premiumSpotlights[0] && searchResultsListings.splice(8, 0, renderSpecialListing(8, 'peekaboo'));
        searchResultsListings.splice(9, 0, null);
    }

    /*
     * Inject 2 New Car Boost listings if available
     * first one at index 6 or the first listing in the 3rd row on desktop
     * second at index 21 if there are 75 or more results per page
     */
    if (enableNewCarBoost) {
        if (boostListings[0]) {
            searchResultsListings.splice(6, 0, renderSpecialListing(0, 'boost'));
        // Ensure there is enough listing slots to fill the row before splicing into results
        } else if (searchResultsListings.length > 8) {
            searchResultsListings.splice(6, 0, <SellYourCarPlacementContainer key="SellYourCarPromo_6" />);
        }

        if (resultsPerPage >= 75 && resultCount >= 75) {
            boostListings[1] && searchResultsListings.splice(22, 0, renderSpecialListing(1, 'boost'));
        }
    }

    /*
     * Inject My Wallet placement at spot #15
     */
    if (searchResultsListings.length > 14 && enableSrpPromoCards) {
        searchResultsListings.splice(
            14,
            0, (
                <MyWalletPlacementContainer
                    key="walletPlacementContainer"
                    changePlacement={changeWalletPlacement}
                />
            )
        );
    }

    // Inject CPO Card at spot #23
    if (showCPOCard && searchResultsListings.length > 22) {
        searchResultsListings.splice(
            22,
            0, (
                <CertifiedPreOwnedContainer
                    data={cpoData}
                    makeFilterValues={makeFilterValues}
                />
            )
        );
    }

    const isPrivateSellerPage = filtersValues?.sellerType?.length === 1 && filtersValues?.sellerType?.includes('p');

    if (enableSrpPromoCards && !showCPOCard && !isPrivateSellerPage && searchResultsListings.length > 22) {
        searchResultsListings.splice(22, 0, <SellYourCarPlacementContainer key="SellYourCarPromo_22" />);
    }

    /*
    * if on a private seller page, inject placement in spot #23, shows if cpo card does not
    */
    if (!showCPOCard && enableSrpPromoCards && isPrivateSellerPage) {
        searchResultsListings.splice(22, 0, <PrivateSellerPlacementContainer changePlacement={changeWalletPlacement} />);
    }

    if (showPeekabooSpotlight) {
        // remove peekaboo from carousel listings
        const newArray = [...premiumSpotlights];
        newArray.shift();

        carouselNineSpotlightListings = newArray;
        carouselThreeSpotlightListings = newArray;

    }

    if (showTwoCarousels) {
        // split spotlights between carousels
        if (premiumSpotlights.length > 5) {
            const arrayChunk = Math.ceil((premiumSpotlights.length) / 2);

            const premiumSpotlightsArray = [...premiumSpotlights];

            carouselThreeSpotlightListings = premiumSpotlightsArray.slice(0, arrayChunk);
            carouselNineSpotlightListings = premiumSpotlightsArray.slice(arrayChunk, arrayChunk + premiumSpotlightsArray.length);
        }
    }

    const renderSpotlightCarouselIndex3 = enableSpotlightCarousel && enableMultiCarousel3 && <SpotlightCarouselContainer premiumSpotlights={carouselThreeSpotlightListings} />;
    const renderSpotlightCarouselIndex9 = enableSpotlightCarousel && enableMultiCarousel9 && premiumSpotlights.length > 5 && <SpotlightCarouselContainer premiumSpotlights={carouselNineSpotlightListings} />;

    // chunk all search results listings in sets of 3 representing each row [[item, item, item], [item, item, item], ...]
    const chunkedListingsSet = _chunk(searchResultsListings, 3);

    // insert 3-column-wide components at specific indexes of chunkedListingsSet
    searchResultsListings.length > 6 && chunkedListingsSet.splice(1, 0, [renderSpotlightCarouselIndex3]);
    searchResultsListings.length > 6 && chunkedListingsSet.splice(3, 0, renderLinerAd(0));
    searchResultsListings.length > 6 && chunkedListingsSet.splice(5, 0, renderSpotlightCarouselIndex9);
    searchResultsListings.length > 14 && chunkedListingsSet.splice(7, 0, [renderLinerAd(1), renderEVArticleCarousel]);
    searchResultsListings.length > 19 && chunkedListingsSet.splice(10, 0, renderLinerAd(2));
    searchResultsListings.length > 33 && chunkedListingsSet.splice(13, 0, renderLinerAd(3));
    searchResultsListings.length > 36 && chunkedListingsSet.splice(16, 0, renderLinerAd(4));

    const showWalletGrid = currentPage === 1 || resultCount === 0;
    const renderMyWalletGrid = (enableMyWallet && showWalletGrid) && <MyWalletGridContainer uiContext="default" />;

    const renderResultsLayout = (
        <div className="row display-flex flex-wrap margin-top-3">
            {chunkedListingsSet.slice(0, 3).flatMap((listing) => listing)}

            {renderMyWalletGrid}

            {chunkedListingsSet.slice(3, chunkedListingsSet.length).flatMap((listing, index) => (
                <LazyComponent
                    componentClass={InventoryListingPlaceholder}
                    componentProps={{ className: 'col-xs-12 col-sm-4' }}
                    key={`inventory-placeholder-${index + 3}`}
                >
                    {listing}
                </LazyComponent>
            ))}

            {isLastPage && <PreorderSearchContainer />}

            {isLastPage && renderSupplementalListings}

            {shouldLoadSimilarListings && isLastPage && (showSimilarListings || (!supplementalEnabled && resultCount < 25)) && (
                <LazyComponent>
                    <SimilarInventoryContainer
                        getListingProps={getListingProps}
                        searchRadius={searchRadius}
                        isLastPage={isLastPage}
                    />
                </LazyComponent>
            )}

            <SRPListingCollection />
        </div>
    );

    // render liner ads outside of a loading indicator in order to prevent ads unmounting
    return (
        <div data-qaid="cntnr-listings">

            {renderResultsCount(resultCount)}

            {renderResultsLayout}

            <SRPDealerDiffModalWrapper />

            <lead-generation
                layout="srpListing"
                is-director
            />
        </div>
    );
}

export const InventorySearchResultsContainer = memo(
    InventorySearchResultsContainerComponent,
    (oldProps, newProps) => _isEqual(newProps.savedListingIds, oldProps.savedListingIds)
        && _isEqual(newProps.listings, oldProps.listings)
        && _isEqual(newProps.compareListingIds, oldProps.compareListingIds)
        && _isEqual(newProps.filtersValues, oldProps.filtersValues)
);

const mapStateToProps = (state) => {
    const currentPage = srpPaginationDuck.selectors.getCurrentPage(state);
    let numRecords = srpPaginationDuck.selectors.getNumRecords(state);
    const savedListingIds = savedInventoryDuck.selectors.getSavedInventoryIds(state);
    const compareListingIds = compareListingsDuck.selectors.getActiveListingIds(state);

    const listings = srpResultsDuck.selectors.getActiveInventory(state);
    const hasAlphaListings = AlphaModule.duck.selectors.hasAlphaListings(state);
    const searchRadius = srpFiltersDuck.selectors.getSelectedSearchRadius(state);
    const resultCount = srpResultsDuck.selectors.getResultsCount(state);
    const isLastPage = currentPage >= Math.ceil(resultCount / numRecords);

    const filtersValues = srpFiltersDuck.selectors.getRequestValues(state);
    const isLoggedIn = authDuck.selectors.isLoggedIn(state);
    const certifiedSponsor = cpoContentDuck.selectors.selectCertifiedContentSimple(state);
    const resultPerPage = state.brand === brands.FORD_BRAND && numRecords === 25 ? numRecords = 30 : numRecords;

    return {
        birf: _get(state, 'birf.pageData.page.BIRF', {}),
        currentPage,
        resultsPerPage: resultPerPage,
        adsLazyLoadingOffset: srpAdsDuck.selectors.getAdsLazyLoadingOffset(state),
        certifiedSponsor,
        hasAlphaListings,
        listings,
        savedListingIds,
        compareListingIds,
        resultCount,
        searchRadius,
        isLastPage,
        filtersValues,
        isLoggedIn,
    };
};

const mapDispatchToProps = mapDispatchToActionProp({
    saveListing,
});

export default connect(mapStateToProps, mapDispatchToProps)(InventorySearchResultsContainer);
